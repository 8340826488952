/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Row, Button, Spin, Space } from 'antd';
import _get from 'lodash/get';
import { useHistory, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'components/Header';
import GreenBox from 'components/GreenBox';
import { useMutate, useFetchUser } from 'hooks';
import authQuery from 'models/auth/query';
import { ICheckLinkingTokenPayload, IConfirmLinkingEngineer } from 'models/auth/interface';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import { useAppDispatch } from 'store';
import queryClient from 'utils/queryClient';

import ecIcon from 'assets/images/ec-icon.png';
import linkedIcon from 'assets/images/linked-icon.svg';
import apIcon from 'assets/images/ap-icon.png';
import Helper from 'utils/helpers';

import './styles.less';

const SALES_TYPE = 'sales';
const ENGINEER_TYPE = 'engineer';

const LinkingConfirmationPage = (): JSX.Element => {
  const { handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { push } = useHistory();
  const [tokenChecking, setTokenChecking] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { refetch } = useFetchUser({ enabled: true });
  const location = useLocation();
  const tokenAfterLogin = _get(location, 'state.tokenInviteAP');
  const typeAfterLogin = _get(location, 'state.typeUserInviteAP');

  const searchParams = new URLSearchParams(search);
  const token = (searchParams.get('token') || tokenAfterLogin) as string;
  const type = (searchParams.get('type') || typeAfterLogin) as string;

  // state
  // APIs
  const { mutateAsync: checkLinkedToken, isLoading: loadingCheckToken } =
    useMutate<ICheckLinkingTokenPayload>(authQuery.checkLinkingToken);
  const { mutateAsync: confirmLinkingEngineer, isLoading: loadingConfirm } =
    useMutate<IConfirmLinkingEngineer>(authQuery.confirmLinkingEngineer);
  const { mutateAsync: confirmLinkingSale, isLoading: loadingSaleConfirm } =
    useMutate<IConfirmLinkingEngineer>(authQuery.confirmLinkingSale);
  const { mutateAsync: logout } = useMutate(authQuery.logout);
  const { data: currentUser } = useFetchUser({ enabled: true });
  const handleLogout = async (data) => {
    setTimeout(() => {
      Helper.removeAuthToken();
      Helper.removeChangePassowrd();
    });
    logout(null);
    await queryClient.invalidateQueries(['currentUser'], {
      refetchType: 'none',
    });
    setTimeout(() => queryClient.resetQueries(['currentUser']));
    if (data?.code) {
      setTimeout(() => push('/'), 100);
    } else {
      setTimeout(() => push('/login', { params: { ...data, token, type: type } }), 100);
    }
  };
  // Check valid token confirm linking engineer
  const checkExpiredToken = () => {
    checkLinkedToken(
      { token: token },
      {
        onSuccess: (data) => {
          // If not logged in & token link not expired, redirected to the login screen.
          if (!Helper.getAuthToken()) {
            setTimeout(() => push('/login', { params: { ...data, token, type } }), 100);
          }
          // if user logging with another account, logout and redirect login
          if (currentUser?.email && currentUser?.email !== data?.email) {
            handleLogout(data);
          }
        },
        onError: () => {
          push('/confirm-linking-url-expired');
        },
        onSettled: () => setTokenChecking(false),
      },
    );
  };

  useEffect(() => {
    if (Helper.getAuthToken()) {
      if (currentUser?.email) {
        checkExpiredToken();
      }
    }
    checkExpiredToken();
  }, [currentUser?.email]);

  // confirm linking engineer
  const handleConfirmLinking = () => {
    console.log(type);
    if (type === SALES_TYPE) {
      confirmLinkingSale(
        {
          token: token,
          reject: false,
        },
        {
          onSuccess: () => {
            Helper.toasts('', t('connectionAP:linkedSuccess'), 'success');
            refetch();
            push('/setting');
          },
          onError: (err) => {
            Helper.toasts('', _get(err, 'message', ''), 'error');
            push('/self-introduction');
          },
        },
      );
    } else {
      confirmLinkingEngineer(
        {
          token: token,
          reject: false,
        },
        {
          onSuccess: () => {
            Helper.toasts('', t('connectionAP:linkedSuccess'), 'success');
            refetch();
            push('/setting');
          },
          onError: (err) => {
            Helper.toasts('', _get(err, 'message', ''), 'error');
            handleLogout(err);
          },
        },
      );
    }
  };
  // refuse linking engineer
  const handleRefuseLinking = () => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          className: 'non-title delete-confirm',
          content: 'Aperportとの連携を拒否してもよろしいですか？',
          okText: t('global:yes'),
          cancelText: t('global:no'),
          onOk: () =>
            type === SALES_TYPE
              ? confirmLinkingSale(
                  {
                    token: token,
                    reject: true,
                  },
                  {
                    onSuccess: () => {
                      push('/dashboard');
                    },
                    onError: (err) => {
                      Helper.toasts('', _get(err, 'message', ''), 'error');
                      handleLogout(err);
                    },
                  },
                )
              : confirmLinkingEngineer(
                  {
                    token: token,
                    reject: true,
                  },
                  {
                    onSuccess: () => {
                      push('/dashboard');
                    },
                    onError: (err) => {
                      Helper.toasts('', _get(err, 'message', ''), 'error');
                      handleLogout(err);
                    },
                  },
                ),
        },
      }),
    );
  };
  // render spin loading
  if (tokenChecking) {
    return (
      <div
        id="big-spin"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10000,
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div>
      <HeaderComponent />
      <Spin spinning={loadingCheckToken || loadingConfirm || loadingSaleConfirm}>
        <div className="linking-confirm-page-wrapper">
          <GreenBox title="Experport-Aperport連携設定" className="confirm-title">
            <form onSubmit={handleSubmit(handleConfirmLinking)}>
              <div className="">
                <div className="icon-linked">
                  <Space>
                    <img src={ecIcon} alt="ec-icon" className="icon-size" />
                    <img src={linkedIcon} alt="linked-icon" className="icon-size" />
                    <img src={apIcon} alt="ap-icon" className="icon-size" />
                  </Space>
                </div>
                {type === SALES_TYPE ? (
                  <Row justify="center" className="question-wrapper">
                    <div className="question-confirm">Experport-Aperport連携設定</div>
                    <div className="notice-information">
                      Aperportと連携をするとExperportで入力した情報が連携されます。
                      <br />
                      【連携される情報】
                      <br />
                      自己紹介の情報がAperportの営業担当として掲載されます。
                    </div>
                  </Row>
                ) : (
                  <Row justify="center" className="question-wrapper">
                    <div className="question-confirm">
                      AperportにExperportの情報の連携を許可しますか？
                    </div>
                    <div className="notice-information">
                      Experportにおいて、経験やスキルなどをご登録いただくと <br />
                      Aperport にて最新の情報が表示されるようになります。
                    </div>
                  </Row>
                )}
              </div>
              <Row justify="center">
                <Button
                  className="admit-button registration-btn second-color"
                  htmlType="submit"
                  type="primary"
                >
                  許可する
                </Button>
              </Row>

              <div className="refuse">
                <a className="refuse-button" onClick={() => handleRefuseLinking()}>
                  拒否する
                </a>
              </div>
            </form>
          </GreenBox>
        </div>
      </Spin>
    </div>
  );
};

export default LinkingConfirmationPage;
